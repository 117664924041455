import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import SEO from '../components/seo';
// images
import WhyImg1 from '../images/why1.jpg';
import WhyImg2 from '../images/why2.jpg';
import '../styles/app.scss';

const schedule = ({ data }) => {
    const { wordpressPage: post } = data;
    console.log(post);
    const test_title = post.yoast_title;
    //const new_test_title = test_title.replace('&#039;', "'");
    const inlineCss = {
        maxWidth: '600px',
        marginTop: '50px',
    };

    //console.log(post.yoast_json_ld[0].wordpress__graph[1].description);

    return (
        <>
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />
                    <iframe
                        src="https://calendar.google.com/calendar/embed?src=wrts3189%40gmail.com&ctz=Australia%2FMelbourne"
                        className="googlecal"
                    />   
                 
                </div>
            </section>

    

          

         
            <Footer />
        </>
    );
};

export default schedule;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
        }
    }
`;
